<template>
  <section>
    <v-row class="px-5 py-5">
      <v-card
        color="purple darken-4"
        dark
        width="100%"
        class="mb-4"
        height="150px"
      >
        <v-card-title class="font-weight-bold display-2 ml-2 mt-3">
          <span class="purple--text lighten-3 mx-2 text-capitalize">Hookah</span>
          Products
        </v-card-title>
      </v-card>
    </v-row>
      <v-row>
    <v-col
      v-for="n in 2"
      :key="n"
      class="d-flex child-flex"
      cols="12"
      lg="4"
    >
      <v-img
        :src="require(`@/assets/store/hookah_${n+1}.png`)"
        alt="A1 Smoke Shop | Hookah"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
  </section>
</template>

<script>
export default {
  name: "gallery",
};
</script>

<style>
</style>